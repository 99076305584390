<template>
    <div class="container mt-5">
      <h2 class="mb-4">Buyer Payment Table</h2>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Payment ID</th>
            <th>Buyer ID</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="payment in payments" :key="payment.id">
            <td>{{ payment.id }}</td>
            <td>{{ payment.buyerId }}</td>
            <td>{{ payment.amount }}</td>
            <td>{{ payment.date }}</td>
            <td>{{ payment.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BuyerPaymentTable',
    data() {
      return {
        payments: [
          { id: 1, buyerId: 201, amount: 250, date: '2024-06-01', status: 'Completed' },
          { id: 2, buyerId: 202, amount: 150, date: '2024-06-10', status: 'Pending' },
          { id: 3, buyerId: 203, amount: 400, date: '2024-07-01', status: 'Completed' },
          // Add more payment records as needed
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  .table {
    width: 100%;
  }
  </style>
  