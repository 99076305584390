<template>
  <div class="mainClass">
    <div class="sidebar">
      <h2>Dashboard</h2>
      <nav>
        <div style="display: flex;">
          <div style="width: 50px;">
        <ul>
          <li><a href="#" @click.prevent="showPage('profile')"><i class="bi bi-person-circle"></i></a></li>
          <li><a href="#" @click.prevent="showPage('cart')"><i class="bi bi-cart3"></i></a></li>
          <li><a href="#" @click.prevent="showPage('payment')"><i class="bi bi-credit-card-2-back"></i></a></li>
          <li><a href="#" @click.prevent="showPage('chart')"><i class="bi bi-graph-up"></i></a></li>

        </ul>
      </div>
      <div class="sideItem">
        <ul>
          <li><a href="#" @click.prevent="showPage('profile')">My Profile</a></li>
          <li><a href="#" @click.prevent="showPage('cart')">My Cart</a></li>
          <li><a href="#" @click.prevent="showPage('payment')">Payment</a></li>
          <li><a href="#" @click.prevent="showPage('chart')">Chart</a></li>

        </ul>
      </div>
      </div>
      </nav>
    </div>

    <div class="main-content">
      <div v-if="currentPage === 'profile'">
        <BayerProfile/>
      </div>
      <div v-if="currentPage === 'cart'">
        <BayerCart/>
      </div>
      <div v-if="currentPage === 'payment'">
        <BayerPayment/>
      </div>
      <div v-if="currentPage === 'chart'">
        <BayerChart/>
      </div>
    </div>
  </div>
</template>

<script>
import BayerProfile from '@/components/bayer/bayerDashboard/BayerProfile.vue';
import BayerCart from '@/components/bayer/bayerDashboard/BayerCart.vue';
import BayerPayment from '@/components/bayer/bayerDashboard/BayerPayment.vue';
import BayerChart from '@/components/bayer/bayerDashboard/BayerChart.vue';

export default {
components: {
  BayerProfile,
  BayerCart,
  BayerPayment,
  BayerChart,

  
},

  data() {
    return {
      currentPage: 'profile',
    }
  },
  methods: {
    showPage(page) {
      this.currentPage = page;
    }
  }
}
</script>

<style lang="scss" scoped>
.mainClass {
  width: 100%;
  height: 100%;
  display: flex;
}

.sidebar {
  width: 250px;
  background-color: #333;
  color: white;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
}

.sidebar h2 {
  color: white;
  margin: 0 0 20px;
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
}

.sidebar nav ul li {
  margin: 20px 0;
}

.sidebar nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  text-align: center;

}

.sidebar nav ul li a:hover {
  color: rgb(0, 0, 0);
  padding: 10px 5px 10px 5px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
}

a:hover::before{
  background-color: white;

}

.main-content {
  height: 100dvh;
  flex: 1;
  background-image: radial-gradient(blue, rgb(87, 0, 128));
  background-color: rgb(17, 0, 112);
  padding: 20px;
  overflow-y: auto;
  color: white;
}


</style>
