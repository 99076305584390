<template>
    <div style="height: 30dvh; width: 100%; background-color: rgb(0, 0, 0); position: relative;">

      
      <div>
        <div>
    <!-- ... (existing code) ... -->

    <!-- Footer Section -->
    <footer class="footer">
      <div class="container">
        <div class="ss"  style="display: flex; justify-content: center; gap: 15%;">
          <div class="footer-col">
            <h4>Company</h4>
            <ul>
              <li><a href="#">About Us</a></li>
              <li><a href="#">Our Services</a></li>
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Affiliate Program</a></li>
            </ul>
          </div>
          <div class="footer-col">
            <h4>Get Help</h4>
            <ul>
              <li><a href="#">FAQ</a></li>
              <li><a href="#">Shipping</a></li>
              <li><a href="#">Returns</a></li>
              <li><a href="#">Order Status</a></li>
              <li><a href="#">Payment Options</a></li>
            </ul>
          </div>
          <div class="footer-col">
            <h4>Design Category</h4>
            <ul>
              <li>
                <a href="#">
                  Photos
                  <i class="bi bi-watch category-icon"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  Images
                  <i class="bi bi-bag category-icon"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  3D HDRI
                  <i class="bi bi-shoe category-icon"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  PNG
                  <i class="bi bi-dress category-icon"></i>
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-col">
            <h4>Follow Us</h4>
            <div class="social-links">
              <!-- Bootstrap Icons for social media -->
              <a href="#"><i class="bi bi-facebook"></i></a>
              <a href="#"><i class="bi bi-twitter"></i></a>
              <a href="#"><i class="bi bi-instagram"></i></a>
              <a href="#"><i class="bi bi-linkedin"></i></a>
              <!-- Add more social icons as needed -->
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
        
      </div>
 
        <div class="body">

          
          <div class="box" style="">
          <div class="box-inner">  
          </div>
        </div>
        
      </div>
      
      <footer>
        <div class="footer-content">
          <p>&copy; {{ currentYear }} Graphic Univers</p>
        </div>
      </footer>


    </div>
</template>

<script>
    export default {
      name: 'FooterPart',
      data() {
        return {
          currentYear: new Date().getFullYear(),
        };
      },
      }
</script>

<style lang="scss" scoped>

.footer {
  background-color: #000000;
  color: #fff;
  padding: 40px 0;
}

.footer h4 {
  font-size: 20px;
  margin-bottom: 15px;
}

.footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
}

.category-icon {
  font-size: 24px;
  margin-left: 5px;
}

.social-links a {
  display: inline-block;
  margin-right: 15px;
  color: #fff;
  font-size: 24px;
}

footer {
  background-color: #000000;
  color: #fff;
  padding: 10px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.footer-content {
  width: 100%;
  height: 4dvh;

  margin: 0 auto;
}





.body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  // background-color: #01101D;
}
.box {
  position: relative;
  width: 100dvw;
  height: 100%;
}

.box:before {
  position: absolute;
  content: '';
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  top: 50%; left: 50%;
  transform: translate(-50%, -50%) scale(.7);
  filter: blur(0);
  opacity: 1;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
}
// .box:hover:before {
//   transform: translate(-50%, -50%) scale(1);
//   filter: blur(50px);
// }
.box-inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.box:before,
.box-inner {
  background-color: #000000;
  // box-shadow: 2px 2px 12px 0 rgba(0,0,0,0.58);
  border-radius: 0px;
  
  background-image: 
    url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3e%3cstop stop-color='%23fcc900' stop-opacity='.25' offset='0%25'/%3e%3cstop stop-color='%23fcc900' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e"),
    url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3e%3cstop stop-color='%23fcc900' stop-opacity='.25' offset='0%25'/%3e%3cstop stop-color='%23fcc900' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e"),
    url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3e%3cstop stop-color='%23fcc900' stop-opacity='.25' offset='0%25'/%3e%3cstop stop-color='%23fcc900' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e");
  background-repeat: repeat-x;
  background-size: 1600px 50%;
  background-position: 0 130%, -50px 130%, 500px 130%;
  
  animation: 20s waves linear infinite forwards;
}

@keyframes waves {
  to {
    background-position: 1600px 130%, 3150px 130%, 5300px 130%;
  }
}
</style>