<template>
  <div class="container">
    <!-- Main content -->
    <div class="main" >
      <template v-if="role !== 2">
      <button  type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Create Your Seller Account
      </button>
    </template>
    </div>

    <!-- Profile Image -->
    <div class="circle">
      <img :src="profileData ? profileData.profile_photo : ''" alt="Profile Image" />
    </div>

    <!-- Profile Card -->
    <div class="card cardArea">
      <div class="card-body">
        <h5 class="card-title d-flex justify-content-between align-items-center">
          Profile Information
          <button class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#editProfileModal">
            <i class="bi bi-pencil-fill"></i>
          </button>
        </h5>
        <p v-if="profileData" class="mt-3">My ID: {{ profileData.id }}</p>
        <p v-if="profileData">First Name: {{ profileData.first_name }}</p>
        <p v-if="profileData">Last Name: {{ profileData.last_name }}</p>
        <p v-if="profileData">Email: {{ profileData.email }}</p>
        <div v-else class="text-center mt-5">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Create Seller Account Button -->

    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <PopupForm :id="id" :token="token"/>
      </div>
    </div>

    <!-- Edit Profile Modal -->
    <div class="modal fade" id="editProfileModal" tabindex="-1" aria-labelledby="editProfileModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Profile</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- Edit Profile Form Fields -->
            <div class="mb-3">
              <label for="firstName" class="form-label">First Name</label>
              <input type="text" class="form-control" id="firstName" v-model="editedProfile.first_name">
            </div>
            <div class="mb-3">
              <label for="lastName" class="form-label">Last Name</label>
              <input type="text" class="form-control" id="lastName" v-model="editedProfile.last_name">
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input type="email" class="form-control" id="email" v-model="editedProfile.email">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="saveProfileChanges">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import PopupForm from '@/components/bayer/PopupForm.vue';

export default {
  components: {
    PopupForm
  },
  data() {
    return {
      profileData: null,
      editedProfile: {
        first_name: '',
        last_name: '',
        email: ''
      },
      token: localStorage.getItem('token') || ''
    };
  },
  computed: {
    role() {
      return parseInt(localStorage.getItem('role'), 10);
    }
  },
  mounted() {
    this.fetchUserProfile();
  },
  methods: {
    fetchUserProfile() {
      if (!this.token) {
        console.error('Token is missing.');
        return;
      }

      axios.get('/user/profile', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      })
      .then(response => {
        this.profileData = response.data;
        this.editedProfile.first_name = response.data.first_name;
        this.editedProfile.last_name = response.data.last_name;
        this.editedProfile.email = response.data.email;
      })
      .catch(error => {
        console.error('Error fetching user profile:', error);
      });
    },
    saveProfileChanges() {
      axios.put('/user/profile', this.editedProfile, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        }
      })
      .then(response => {
        this.profileData = response.data;
        this.$refs.editProfileModal.hide(); // Use native Vue method to hide modal
      })
      .catch(error => {
        console.error('Error saving profile changes:', error);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 200px;
  background-image: url('@/assets/Gallery/imgA.jpg');
  background-size: cover;
  background-position: center;
}

.circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-top: -100px;
  margin-left: 10%;
  background-image: url('@/assets/Gallery/imgA.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cardArea {
  width: 100%;
  margin-top: 100px;
}
</style>
