<template>
  <div class="modal-content">
    <div class="modal-header">
      <h1> user id{{ id }}</h1>
      <h5 class="modal-title" id="exampleModalLabel">CREATE YOUR SELLER CARD</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <!-- Alerts for success and error messages -->
      <div v-if="successMessage" class="alert alert-success" role="alert">{{ successMessage }}</div>
      <div v-if="errorMessage" class="alert alert-danger" role="alert">{{ errorMessage }}</div>

      <!-- Form inputs -->
      <div class="mb-3">
        <label for="businessName" class="form-label">Card Title:</label>
        <input type="text" id="businessName" class="form-control" v-model="sellerForm.gigTitle" required>
      </div>

      <div class="mb-3">
        <label for="businessCategory" class="form-label">Category:</label>
        <select id="businessCategory" class="form-select" v-model="selectedCategoryId" @change="updateSubcategories" required>
          <option value="" disabled>Select a category</option>
          <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.category_name }}</option>
        </select>
      </div>

      <div class="mb-3" v-if="subcategories.length > 0">
        <label for="businessSubCategory" class="form-label">Subcategory:</label>
        <select id="businessSubCategory" class="form-select" v-model="selectedSubCategoryId" required>
          <option value="" disabled>Select a subcategory</option>
          <option v-for="subcategory in subcategories" :key="subcategory.id" :value="subcategory.id">{{ subcategory.sub_category_name }}</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="tagList" class="form-label">Select Tag:</label>
        <div class="row">
          <div class="col-4" v-for="(tag, index) in tags" :key="index">
            <div class="form-check">
              <input type="checkbox" :id="'tag' + index" class="form-check-input" v-model="sellerForm.selectedTags" :value="tag">
              <label :for="'tag' + index" class="form-check-label">{{ tag }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      <button type="button" class="btn btn-primary" @click="saveChanges">Save changes</button>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
export default {
  props: {
    userId: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sellerForm: {
        gigTitle: '',
        category: {
          id: '',
          categoryName: ''
        },
        subCategory: {
          id: '',
          subCategoryName: ''
        },
        selectedTags: []
      },
      selectedCategoryId: null, // This will temporarily store the selected category ID
      selectedSubCategoryId: null, // This will temporarily store the selected subcategory ID
      categories: [],
      subcategories: [],
      tags: ['Graphic', '3D', 'Animation', 'Illustration','Digital', 'Pattern', 'Poly Model','2D','Art','Pattern','Colorful','Black & White'],
      successMessage: '', // To hold the success message
      errorMessage: '' // To hold the error message
    };
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    fetchCategories() {
      axios.get('/getCategories')
        .then(response => {
          this.categories = response.data;
          console.log('Fetched categories:', this.categories);
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
          this.errorMessage = 'Failed to fetch categories. Please try again later.';
        });
    },
    updateSubcategories() {
      const selectedCategory = this.categories.find(category => category.id === this.selectedCategoryId);
      if (selectedCategory) {
        this.sellerForm.category.id = selectedCategory.id;
        this.sellerForm.category.categoryName = selectedCategory.category_name;
        
        axios.get(`/getSubcategories/${selectedCategory.id}`)
          .then(response => {
            this.subcategories = response.data;
            console.log('Fetched subcategories:', this.subcategories);
            this.selectedSubCategoryId = null; // Reset selected subcategory
          })
          .catch(error => {
            console.error('Error fetching subcategories:', error);
            this.errorMessage = 'Failed to fetch subcategories. Please try again later.';
          });
      } else {
        this.sellerForm.category.id = '';
        this.sellerForm.category.categoryName = '';
        this.subcategories = [];
        this.selectedSubCategoryId = null;
      }
    },
    saveChanges() {
      const { userId, token } = this;
      const requestData = {
        gigTitle: this.sellerForm.gigTitle,
        category: {
          id: this.sellerForm.category.id,
          categoryName: this.sellerForm.category.categoryName
        },
        subCategory: {
          id: this.selectedSubCategoryId,
          subCategoryName: this.subcategories.find(subcategory => subcategory.id === this.selectedSubCategoryId)?.sub_category_name || ''
        },
        selectedTags: this.sellerForm.selectedTags
      };

      axios.post('/savegig', requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'User-ID': userId
        }
      })
      .then(response => {
        const userId = localStorage.getItem('userId'); // Retrieve user ID
console.log(userId); // Log the user ID to the console

        console.log('Response from Laravel:', response.data);
        this.successMessage = 'Gig saved successfully!';
        this.errorMessage = '';
      // })
      //after 5 second refresh page
      setTimeout(() => {
    window.location.reload();
  }, 5000);
})
      .catch(error => {
        console.error('Error:', error);
        this.errorMessage = 'Failed to save gig. Please try again.';
        this.successMessage = '';
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-content { color: black; }
/* Additional scoped styles can go here */
</style>
