<template>
    <div style="width:100%; height:100%;">
        <div class="classMain" style="width: 100%; height: 60dvh;">

            <h2>About Us</h2>
        </div>

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

.classMain{
    background-image: url('../../assets/backgroundA.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    display: flex;
}
 h2{
    text-align: center;
    color: white;
 }

</style>