import { createStore } from 'vuex'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {

    submitForm(state, formData) {
      state.user = formData;
    },

  },
  actions: {
  },
  modules: {
  }
})

