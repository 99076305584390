<template>
  <div class="bg-image-container">
    <div style="width: 100%; height: 100%;">
      <h1 style="color: white; font-weight: 600;">GET YOUR ASSETS</h1>
      <div class="ss outline-light ">
        <div v-for="(card, index) in cards" :key="index" class="card bg-transparent card border-light" style="width: 18rem;">
          <div style="background-color: black;"><img :src="card.image" class="card-img-top" :alt="card.title"></div>
          <div class="card-body">
            <h5 class="card-title text-white text">{{ card.title }}</h5>
            <p class="card-text text-white text">{{ card.description }}</p>
            <a href="#" class="btn btn-outline-light text">{{ card.btn_name }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        { title: 'HDRI', description: 'Some quick example text for HDRI', image: require('@/assets/WelcomeCard/HDRI.jpg'), btn_name: 'HDRI' },
        { title: 'Texture', description: 'Some quick example text for Texture', image: require('@/assets/WelcomeCard/Texture.jpg'), btn_name: 'Texture' },
        { title: 'PNG', description: 'Some quick example text for PNG', image: require('@/assets/WelcomeCard/Png.png'), btn_name: 'PNGs' },
        { title: 'Images', description: 'Some quick example text for Images', image: require('@/assets/WelcomeCard/Image.jpg'), btn_name: 'Images' },
        { title: 'Photos', description: 'Some quick example text for Photos', image: require('@/assets/WelcomeCard/Photo.jpg'), btn_name: 'Photos' }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.bg-image-container {
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/Nebula.jpg');
  background-size: cover;
  background-position: center;
  background-color: white;
}
h1 {
  text-align: center;
}

.ss {
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.card {
  width: 18rem;
  transition: border-color 0.3s ease; /* Add transition for border-color */
}

.card:hover {
  border: 1px solid #007bff; /* Example border color change */
}

.text{
  font-weight: 600;
}

.card-body {
  background-color: rgba(0, 0, 0, 0.315); /* Set card body background color to transparent */
}
</style>
