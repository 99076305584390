<template>
  <div class="main">
    <div class="container">
      <img src="/src/assets/graphic.png" alt="">
      <div class="row">
        <div v-for="item in items" :key="item.id" class="col-md-12 mb-12">
          <div class="card h-100">
            <div class="card">
            </div>
            <div ref="threeContainer" class="three-container"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

export default {
  data() {
    return {
      items: [
        {
          id: 1,
          title: 'Earth',
          description: 'This is a 3D model of Earth.',
          obj: '/earth.obj',
          texture: '/earthTextue.png',
          hdri: '/qwantani_2k.hdr', // Path to your HDRI image
        },


        // Add more items as needed
      ],
    };
  },
  // {
  //         id: 1,
  //         title: 'Earth',
  //         description: 'This is a 3D model of Earth.',
  //         obj: 'https://graphicwebapi.mysoftcode.com/storage/Items/1_1719407276_earth.obj',
  //         texture: '/earthTextue.png',
  //         hdri: '/qwantani_2k.hdr', // Path to your HDRI image
  //       },
  mounted() {
    this.$nextTick(() => {
      this.items.forEach((item, index) => {
        this.initThree(this.$refs.threeContainer[index], item.obj, item.texture, item.hdri);
      });
    });
  },
  methods: {
    initThree(container, objPath, texturePath, hdriPath) {
      // Get the container dimensions and set the scene size
      const width = container.clientWidth;
      const height = container.clientHeight;
      
      // Set up scene, camera, and renderer
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(width, height);
      renderer.setClearColor(0x00000000); // Set the clear color
      container.appendChild(renderer.domElement);

      // Load HDRI environment map
      new RGBELoader()
        .setDataType(THREE.UnsignedByteType)
        .load(hdriPath, (texture) => {
          const envMap = pmremGenerator.fromEquirectangular(texture).texture;
          scene.background = envMap;
          scene.environment = envMap;
          texture.dispose();
          pmremGenerator.dispose();
        });

      // Add directional light
      const directionalLight = new THREE.DirectionalLight(0xFFFFFF, 1);
      directionalLight.position.set(5, 5, 5); // Adjust position as needed
      scene.add(directionalLight);

      // Add ambient light
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5); // Color, Intensity
      scene.add(ambientLight);

      // Declare the object variable in the outer scope
      let object;

      // Load 3D object using OBJLoader
      const loader = new OBJLoader();
      loader.load(objPath, (loadedObject) => {
        object = loadedObject;
        object.position.set(0, 0, 0); // Adjust the position as needed
        object.scale.set(1, 1, 1); // Adjust the scale as needed

        // Load texture
        const textureLoader = new TextureLoader();
        const texture = textureLoader.load(texturePath, () => {
          object.traverse((child) => {
            if (child instanceof THREE.Mesh) {
              child.material.map = texture;
              child.material.needsUpdate = true;
            }
          });
        });

        scene.add(object);
      });
      
      // Set up camera position
      camera.position.set(0, 0, 10); // Adjust the camera position as needed
      camera.lookAt(0, 0, 0);

      // Create OrbitControls
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
      controls.dampingFactor = 0.25; // damping factor (0 = no damping, 1 = full damping)
      controls.minDistance = 5; // Minimum zoom distance
      controls.maxDistance = 20; // Maximum zoom distance

      // Handle window resize
      window.addEventListener('resize', () => {
        const newWidth = container.clientWidth;
        const newHeight = container.clientHeight;
        renderer.setSize(newWidth, newHeight);
        camera.aspect = newWidth / newHeight;
        camera.updateProjectionMatrix();
      });

      // Animation loop
      const animate = () => {
        requestAnimationFrame(animate);
        if (object) {
          object.rotation.y += 0.04; // Add rotation animation
        }
        controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true
        renderer.render(scene, camera);
      };

      // PMREM Generator for HDRI
      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      pmremGenerator.compileEquirectangularShader();

      animate();
    },
    downloadModel(objPath) {
      const link = document.createElement('a');
      link.href = objPath;
      link.download = objPath.split('/').pop();
      link.click();
    },
  },
};
</script>

<style scoped>
.main {
  background-image: url(/src/assets/Universe.jpg);
}
.container {
  background-color: rgb(0, 0, 51);
  padding: 10px;
}
.card {
  background-color: rgb(25, 0, 71) !important;
  border: 1px solid rgb(25, 0, 71);
  color: white;
}
.three-container {
  width: 100%;
  height: 300px;
}
</style>
