import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginPage from '../views/LoginPage.vue'
import RegisterPage from '../views/RegisterPage.vue'
import GalleryView from '../views/GalleryView.vue'
import ContactView from '../views/ContactView.vue'
import MyTest from '../views/MyTest.vue'

import BayerView from '../views/BayerHome/BayerView.vue'
import SellerView from '../views/SellerHome/SellerView.vue'
import SellerHomeView from '../views/SellerHome/SellerHomeView.vue'
import SellerAbout from '../views/SellerHome/SellerAbout.vue'
import SellerContact from '../views/SellerHome/SellerContact.vue'
import MyGallery from '../views/SellerHome/MyGallery.vue'

import ProfilePage from '../views/ProfilePage.vue'

import RegisterWelcome from '../views/welcome/RegisterWelcome.vue'


// import SellerCategoryView from '../views/SellerHome/SellerCategoryView/HDRIView.vue'
import HDRIView from '../views/SellerHome/SellerCategoryView/HDRIView.vue'
import ThreeDModelView from '../views/SellerHome/SellerCategoryView/ThreeDModelView.vue'
import ImagesVIew from '../views/SellerHome/SellerCategoryView/ImagesVIew.vue'
import PhotosView from '../views/SellerHome/SellerCategoryView/PhotosView.vue'
import PngsView from '../views/SellerHome/SellerCategoryView/PngsView.vue'
import TexturesView from '../views/SellerHome/SellerCategoryView/TexturesView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/mytest',
    name: 'mytest',
    component: MyTest
  },


  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },

  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },

  {
    path: '/register',
    name: 'register',
    component: RegisterPage
  },

  {
    path: '/gallery',
    name: 'gallery',
    component: GalleryView
  },

  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },


  {
    path: '/welcome',
    name: 'welcome',
    component: RegisterWelcome
  },

  {
    path: '/bayerhome',
    name: 'bayer',
    component: BayerView
  },

  {
    path: '/sellerhome',
    name: 'seller',
    component: SellerView
  },

  {
    path: '/mygallery',
    name: 'mygallery',
    component: MyGallery
  },

  {
    path: '/profileview',
    name: 'profile',
    component: ProfilePage
  },

  {
    path: '/sellerHome/homeview',
    name: 'sellerHomeView',
    component: SellerHomeView
  },

  {
    path: '/sellerHome/about',
    name: 'sellerHomeAbout',
    component: SellerAbout
  },

  {
    path: '/sellerHome/contact',
    name: 'sellerHomeContact',
    component: SellerContact
  },



  {
      path: '/sellerHome/category/hdriView',
      name: 'hdriView',
      component: HDRIView,
      props: route => ({
        user_id: route.query.user_id,
        id: route.query.id,
        category_id: route.query.category_id
      })
  },
  {
    path: '/sellerHome/category/3dmodelView',
    name: '3dmodelView',
    component: ThreeDModelView,
    props: route => ({
      user_id: route.query.user_id,
      id: route.query.id,
      category_id: route.query.category_id
    })
  },
  {
    path: '/sellerHome/category/imagesView',
    name: 'imagesView',
    component: ImagesVIew,
    props: route => ({
      user_id: route.query.user_id,
      id: route.query.id,
      category_id: route.query.category_id
    })
  },
  {
    path: '/sellerHome/category/photosView',
    name: 'photosView',
    component: PhotosView,
    props: route => ({
      user_id: route.query.user_id,
      id: route.query.id,
      category_id: route.query.category_id
    })
  },
  {
    path: '/sellerHome/category/texturesView',
    name: 'texturesView',
    component: TexturesView,
    props: route => ({
      user_id: route.query.user_id,
      id: route.query.id,
      category_id: route.query.category_id
    })
  },
  {
    path: '/sellerHome/category/pngsView',
    name: 'pngsView',
    component: PngsView,
    props: route => ({
      user_id: route.query.user_id,
      id: route.query.id,
      category_id: route.query.category_id
    })
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
