<template>
    <div>
         <h1 style="color:white !important; text-align: center;">Textures</h1>
<div class="gallery">
  <div v-for="item in items" :key="item.id" class="card">
    <img :src="item.image" alt="Gallery Image" class="card-img">
    <div class="card-content">
      <h2 class="card-title">{{ item.title }}</h2>
      <p class="card-description">{{ item.description }}</p>
    </div>
  </div>
</div>

    </div>
</template>

<script>
export default {
        data() {
      return {
        currentPage: 'images',
        items: [
          { id: 1, image: require('@/assets/Gallery/TextureA.png'), title: 'Image A', description: 'Description of Image A' },
          { id: 2, image: require('@/assets/Gallery/TextureB.png'), title: 'Image B', description: 'Description of Image B' },
          { id: 3, image: require('@/assets/Gallery/TextureC.png'), title: 'Image C', description: 'Description of Image C' },
          { id: 4, image: require('@/assets/Gallery/imgD.jpg'), title: 'Image D', description: 'Description of Image D' },
          { id: 5, image: require('@/assets/Gallery/imgE.jpg'), title: 'Image E', description: 'Description of Image E' },
          { id: 6, image: require('@/assets/Gallery/imgF.jpg'), title: 'Image F', description: 'Description of Image F' },
        ]
      }
    },
}
</script>

<style lang="scss" scoped>
.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
  }
  
  .card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 10px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .card-content {
    padding: 15px;
  }
  
  .card-title {
    font-size: 1.5em;
    margin: 0 0 10px;
  }
  
  .card-description {
    font-size: 1em;
    color: #666;
  }

</style>