<template>
  <div class="main">
    <div class="welcome-container">
      <h1>Congratulations, {{ user.first_name }}!</h1>
      
      <div class="user-info">
        <div class="outerimg">
          <img :src="user.profile_photo" alt="Profile Picture">
        </div>
        <div class="Topic">Welcome To Graphic Universe</div>
        <button @click="navigateToAnotherPage" class="btn btn-outline-primary">Get Started</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      // Retrieve data from localStorage
      const token = localStorage.getItem('token');
      const id = localStorage.getItem('id');
      const first_name = localStorage.getItem('firstName');
      const profile_photo = localStorage.getItem('profilePhoto');
      
      return {
        token,
        id,
        first_name,
        profile_photo,
      };
    },
  },
  methods: {
    navigateToAnotherPage() {
      // Programmatic navigation using $router
      this.$router.push({ 
        name: 'bayer',
        params: {
          token: localStorage.getItem('token'),
          id: localStorage.getItem('id'),
          firstName: localStorage.getItem('firstName'),
          profilePhoto: localStorage.getItem('profilePhoto')
        }
      }); // Assuming 'bayer' is the name of another route
    },
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/BackgroundSlide/imgABC.png');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-container {
  width: 80vw;
  padding: 20px;
  border-radius: 50px;
  margin: auto;
  text-align: center;
  align-items: center;
  background-color: black;
  color: white;
}

.Topic {
  color: white;
}

.outerimg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: url('../../assets/earth.png');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid blue;
  background-color: white;
  margin: auto; /* Center the container horizontally */
}

.user-info {
  margin-top: 20px;
  font-size: 35px;
  font-weight: 600;
}

.user-info img {
  max-width: 100px;
  border-radius: 50%;
}
</style>
