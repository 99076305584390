<template>
  <div>
    <h1 style="color:white !important; text-align: center;">3D Models Gallery</h1>
    <div class="gallery">
      <div v-for="item in items" :key="item.id" class="card">
        <div class="card-content">
          <h2 class="card-title">{{ item.title }}</h2>
          <p class="card-description">Price: {{ item.price }}</p>
        </div>
        <div class="three-container" :ref="'threeContainer-' + item.id"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export default {
  props: ['user_id', 'id', 'category_table_id'],
  data() {
    return {
      items: [],
    };
  },
  created() {
    console.log('Received User ID:', this.user_id);
    console.log('Received ID:', this.id);
    console.log('Received Category Table ID:', this.category_table_id);
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    fetchItems() {
  // Retrieve the token and ID from local storage
  const token = localStorage.getItem('token'); // Change 'token' to your actual key
  const id = localStorage.getItem('id'); // Change 'id' to your actual key

  // Make the API call with the token and ID
  fetch('/getitems', {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'X-User-ID': id,
    }
  })
  .then(response => response.json()) // Convert response to JSON
  .then(data => {
    this.items = data.items; // Assuming API returns items data as an array
    console.log('Fetched Items:', this.items);
    this.initThreeScenes(); // Initialize Three.js scenes after fetching items
  })
  .catch(error => {
    console.error('Error fetching items:', error);
  });
},

    initThreeScenes() {
  this.$nextTick(() => {
    this.items.forEach(item => {
      const container = this.$refs[`threeContainer-${item.id}`];
      if (container && container.length > 0) {
        this.initThree(container[0], item.item_file, item.texture);
      }
    });
  });
},

    initThree(container, objPath, texturePath) {
      // Set up scene, camera, and renderer
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(container.clientWidth, container.clientHeight);
      renderer.setClearColor(0x009999FF); // Set the clear color
      container.appendChild(renderer.domElement);

      // Add lights
      const directionalLight = new THREE.DirectionalLight(0xFFFFFF, 1);
      directionalLight.position.set(5, 5, 5);
      scene.add(directionalLight);

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      // Load OBJ model
      const loader = new OBJLoader();
      loader.load(objPath, object => {
        object.position.set(0, 0, 0);
        object.scale.set(1, 1, 1);

        // Load texture
        const textureLoader = new TextureLoader();
        const texture = textureLoader.load(texturePath, () => {
          object.traverse(child => {
            if (child instanceof THREE.Mesh) {
              child.material.map = texture;
              child.material.needsUpdate = true;
            }
          });
        });

        scene.add(object);
      });

      // Set camera position
      camera.position.set(0, 0, 10);
      camera.lookAt(0, 0, 0);

      // Orbit controls
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.25;

      // Handle window resize
      window.addEventListener('resize', () => {
        const width = container.clientWidth;
        const height = container.clientHeight;
        renderer.setSize(width, height);
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
      });

      // Animation loop
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };

      animate();
    },
  },
};
</script>

<style scoped>
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.card-content {
  padding: 15px;
}

.card-title {
  font-size: 1.5em;
  margin: 0 0 10px;
}

.card-description {
  font-size: 1em;
  color: #666;
}

.three-container {
  width: 100%;
  height: 200px;
  background: #f0f0f0;
}
</style>
