<template>
  <div>
    <h1>Hello HDRI</h1>
    <p>User ID: {{ user_id }}</p>
    <p>ID: {{ id }}</p>
  </div>
</template>

<script>
export default {
  props: ['user_id', 'id'],
  created() {
    console.log('Received User ID:', this.user_id);
    console.log('Received ID:', this.id);
  }
}
</script>

<style scoped>
/* Add your scoped styles here if needed */
</style>
