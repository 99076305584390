<template>
    <div class="mainClass">
      <div class="sidebar">
        <h2>CATEGORY</h2>
        <nav>
          <ul>
            <li><a href="#" @click.prevent="showPage('images')">Images</a></li>
            <li><a href="#" @click.prevent="showPage('3d-model')">3D Model</a></li>
            <li><a href="#" @click.prevent="showPage('hdri')">HDRI</a></li>
            <li><a href="#" @click.prevent="showPage('texture')">Texture</a></li>
            <li><a href="#" @click.prevent="showPage('png')">PNG</a></li>
            <li><a href="#" @click.prevent="showPage('photos')">Photos</a></li>
          </ul>
        </nav>
      </div>
  
      <div class="main-content">
        <div v-if="currentPage === 'images'">
            <CategoryImage/>
        </div>
        <div v-if="currentPage === '3d-model'">
          <Category3DModel/>
        </div>
        <div v-if="currentPage === 'hdri'">
          <CategoryHDRI/>
        </div>
        <div v-if="currentPage === 'texture'">
          <CategoryTexture/>
        </div>
        <div v-if="currentPage === 'png'">
          <CategoryPNG/>
        </div>
        <div v-if="currentPage === 'photos'">
          <CategoryPhotos/>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import CategoryImage from '@/components/gallery/categoryList/CategoryImage.vue'
import Category3DModel from '@/components/gallery/categoryList/Category3DModel.vue'
import CategoryHDRI from '@/components/gallery/categoryList/CategoryHDRI.vue'
import CategoryTexture from '@/components/gallery/categoryList/CategoryTexture.vue'
import CategoryPNG from '@/components/gallery/categoryList/CategoryPNG.vue'
import CategoryPhotos from '@/components/gallery/categoryList/CategoryPhotos.vue'

export default {
  components: {
    CategoryImage,
    Category3DModel,
    CategoryHDRI,
    CategoryTexture,
    CategoryPNG,
    CategoryPhotos,
    
  },

    data() {
      return {
        currentPage: 'images',
      }
    },
    methods: {
      showPage(page) {
        this.currentPage = page;
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .mainClass {
    width: 100%;
    height: 100%;
    display: flex;
  }
  
  .sidebar {
    width: 250px;
    background-color: #000000;
    color: white;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    border-top: 2px solid white;
  }
  
  .sidebar h2 {
    color: white;
    margin: 0 0 20px;
  }
  
  .sidebar nav ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
  }
  
  .sidebar nav ul li {
    margin: 20px 0;
  }
  
  .sidebar nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
  }
  
  .sidebar nav ul li a:hover {
    background-color: white;
    color: black;
    // padding: 5px;
    // text-decoration: underline;
  }
  
  .main-content {
    height: 100dvh;
    flex: 1;
    background-image: radial-gradient(blue, rgb(87, 0, 128));
    background-color: rgb(17, 0, 112);
    padding: 20px;
    overflow-y: auto;
    color: white;
  }
  
  
  </style>
  