<template>
<div class="classM">


  <div class="modal fade" id="confirmModal" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmModalLabel">Confirmation</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ message }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" @click="confirm">OK</button>
        </div>
      </div>
    </div>
  </div>



  <div class="formClass">
    <form class="row g-3" @submit.prevent="submitForm">
    <h2 style="color: white; text-align: center;">SignUp Here</h2>
    <!-- <div v-if="status" class="alert alert-success">
            {{ status }}
        </div> -->
    <div v-show="currentStep === 1">
      <input type="text" v-model="first_name" class="form-control fieldClass" placeholder="First name" aria-label="First name">
      <input type="text" v-model="last_name" class="form-control fieldClass" placeholder="Last name" aria-label="Last name">
      <input type="email" v-model="email" class="form-control fieldClass" id="inputEmail4" placeholder="E-mail" aria-label="Last name">
      <input type="password" v-model="password" class="form-control fieldClass" id="inputPassword4" placeholder="Password" aria-label="Last name">

      <div class="fgroup">
        <select id="country" v-model="selectedCountry" class="form-select fieldClass">
          <option value="" selected disabled>Select Your Country</option>
          <option v-for="country in countryList" :key="country.code" :value="country.code">
            <span class="country-option">
              {{ country.name }}
              <img :src="getCountryFlagUrl(country.code)" alt="Country Flag" class="country-icon" />
            </span>
          </option>
        </select>
      </div>

      <div style="display: flex; align-items: center; justify-content: center;" class="flagCode fieldClass">
        <div class="combinedInput form-control" style="width: 25%;">
          <img :src="getCountryFlagUrl(selectedCountry)" alt="" class="" style="width: 30px; height: auto; margin: 0%;">
          <div class="codeDial" readonly > {{ getDialCode(selectedCountry) }}</div>
        </div>
        <input id="phone" v-model="phone" class="form-control" type="number" required placeholder="Phone Number" style="width: 75%;  justify-content: flex-end;"/>
      </div>

      <div class="btnClassA">
        <button @click="nextStep" class="btn btn-primary fieldClass" type="button">Next</button>
      </div>
    </div>

    <div v-show="currentStep === 2">
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label fieldClass">Address</label>
        <input type="text" v-model="living_area" class="form-control fieldClass" placeholder="Living Area" aria-label="Living Area">
        <input type="text" v-model="home_town" class="form-control fieldClass" placeholder="Home Town" aria-label="Home Town">
        <input type="number" v-model="zip_code" class="form-control fieldClass" placeholder="Zip / Postal Code" aria-label="Zip / Postal Code">
      </div>

      <label for="exampleFormControlInput1" class="form-label fieldClass">Gender</label>
<div style="display: flex;" class="fieldClass">
  <div class="form-check">
    <input class="form-check-input" type="radio" v-model="gender" value="male" id="flexRadioDefault1">
    <label class="form-check-label" for="flexRadioDefault1">
      Male
    </label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="radio" v-model="gender" value="female" id="flexRadioDefault2">
    <label class="form-check-label" for="flexRadioDefault2">
      Female
    </label>
        </div>
      </div>

      <div class="btnClassA">
        <button @click="prevStep" class="btn btn-secondary fieldClass" type="button">Prev</button>
        <button @click="nextStep" class="btn btn-primary fieldClass" type="button">Next</button>
      </div>
    </div>

    <div v-show="currentStep === 3">
      <input type="text" v-model="nic" class="form-control fieldClass" placeholder="NIC" aria-label="NIC">
      <input type="date" v-model="birth_day" class="form-control fieldClass" placeholder="Birth Day" aria-label="Birth">
      <div class="form-group fieldClass">
        <label for="nicFile">Upload Your Profile Picture</label>
        <!-- Update the id of the file input to match the method parameter -->
<input type="file" class="form-control" id="profile_photo" ref="profile_photo" @change="handleFileChange" aria-label="File" required>

        <small class="form-text text-muted">Please upload a scanned copy of your National Identity Card (NIC).</small>
      </div>
      <div class="form-check fieldClass">
        <input v-model="check_agree" class="form-check-input" type="checkbox" value="" id="termsCheckbox" required>
        <label class="form-check-label" for="termsCheckbox">
          I have read and agree to the <a href="your_terms_link" target="_blank">Terms of Service</a> and <a href="your_privacy_link" target="_blank">Privacy Policy</a>.
        </label>
      </div>

      <div class="btnClassA">
        <button type="button" @click="prevStep" class="btn btn-secondary fieldClass">Prev</button>
        <button type="submit" class="btn btn-primary fieldClass">Submit</button>
      </div>
      
    </div>
  </form>

    </div>
    </div>
  </template>
<script>
import { countries } from '@/assets/scripts/countries.js';
import axios from 'axios';

export default {
  data() {
    return {
      status: '',
      currentStep: 1,
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      phone: '',
      selectedCountry: '',
      countryList: countries,
      living_area: '',
      home_town: '',
      zip_code: '',
      gender: '',
      nic: '',
      birth_day: '',
      profile_photo: null, // Changed profile_photo to null initially
      check_agree: false, // Changed check_agree to boolean type
    };
  },
  methods: {
    handleFileChange(event) {
  const file = event.target.files[0];
  if (file) {
    this.profile_photo = file;
  }
},


    async submitForm() {
      try {
        console.log('Form submitted!');
        const formData = new FormData(); // Changed to FormData object
        formData.append('first_name', this.first_name);
        formData.append('last_name', this.last_name);
        formData.append('email', this.email);
        formData.append('password', this.password);
        formData.append('selectedCountry', this.selectedCountry);
        formData.append('phone', this.phone);
        formData.append('living_area', this.living_area);
        formData.append('home_town', this.home_town);
        formData.append('zip_code', this.zip_code);
        formData.append('gender', this.gender);
        formData.append('nic', this.nic);
        formData.append('birth_day', this.birth_day);
        // formData.append('profile_photo', this.profile_photo);
        if (this.profile_photo) {
      formData.append('profile_photo', this.profile_photo, this.profile_photo.name);
    } // Append profile photo as Blob/File
        formData.append('check_agree', this.check_agree);

        // Assuming you want to commit form data to the store
        this.$store.commit('submitForm', formData);

        const response = await axios.post('/submit-form', formData);
        const { token, profilePhoto, firstName, id } = response.data;
        
        
// Store token and user data in localStorage
console.log('ID:', id);
console.log('Token:', token);
console.log('First Name:', firstName);
console.log('Profile Photo:', profilePhoto);

// You can now use the response data as needed
// For example, you might want to save the token in local storage
localStorage.setItem('token', token);
localStorage.setItem('id', id);
localStorage.setItem('firstName', firstName);
localStorage.setItem('profilePhoto', profilePhoto);

console.log('Server response:', response.data);

        if (response.data.message) {
          this.status = response.data.message;
          if (window.confirm(this.status)) {
            this.$router.push({ name: 'welcome' });
          }
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        this.status = 'Form submission failed. Please try again.';
      }
    },

    nextStep() {
      if (this.currentStep < 3) {
        this.currentStep++;
      }
    },

    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },

    getCountryFlagUrl(countryCode) {
      return `https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${countryCode}.svg`;
    },

    getCountryName(countryCode) {
      const country = this.countryList.find((country) => country.code === countryCode);
      return country ? country.name : '';
    },

    getDialCode(countryCode) {
      const country = this.countryList.find((country) => country.code === countryCode);
      return country ? country.dial_code : '';
    },
  },
};
</script>

  
  <style scoped>


label{
  color: white;
}

.btnClassA{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
  .classM{
    width:100%;
    height: 100dvh;
    background-color: black;
    justify-content: center;
  display: flex;
  align-items: center;
  }

  /* .formClass{
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;

  } */

  .formClass{
  justify-content: center;
  display: flex;
  align-items: center;
    width: 500px;
    height: 80%;
    background-color: rgba(17, 0, 255, 0.39);
    background-image: linear-gradient(rgba(0, 0, 255, 0.466),rgba(255, 0, 191, 0.712));
    border: 2px solid white;
    border-radius: 20px;
  }

  .form-control , .form-select , .flagCode{
    width: 35dvw;
  }

  .combinedInput{

    display: flex;
    align-items: center;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    /* padding: 0px 5px 0px 5px; */
    height: 37px;
    width: 35%;
  }

  .fieldClass{
    margin: 10px;
  }





.btn{
  width: 100px;
}
/* button {
  background-color: #081729;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
} */

/* button:hover {
  background-color: #081729ad;
} */
  /* Add any additional styles if needed */
  </style>
  