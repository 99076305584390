<template>
  <div>
    <!-- Include AFrontHeader to display user information -->
    <BFrontHeader
    :isLoggedIn="isLoggedIn"
    :firstName="firstName"
    :profilePhoto="profilePhoto"
    @update:isLoggedIn="handleLoginState"
    />

    <div>
        <SellerProfileA/>
      </div>
    
    <!-- Include BayerHeader for additional header content -->
  </div>
</template>

<script>
import BFrontHeader from '@/components/BFrontHeader.vue';
import SellerProfileA from '@/components/seller/SellerProfileA.vue';

export default {
name: 'HomeView',
components: {
  BFrontHeader,
  SellerProfileA,

  
},
data() {
  return {
    isLoggedIn: true, // or false based on initial state
    firstName: '', // default or fetched data
    profilePhoto: '' // default or fetched data
  }
},
methods: {
  handleLoginState(value) {
    this.isLoggedIn = value;
    if (!value) {
      this.firstName = '';
      this.profilePhoto = '';
    }
  }
},
mounted() {
  // Retrieve data from localStorage and set it in component data
  this.firstName = localStorage.getItem('firstName') || '';
  this.profilePhoto = localStorage.getItem('profilePhoto') || '';
}
}
</script>

<style lang="scss" scoped>
/* Add your scoped styles here if needed */
</style>
