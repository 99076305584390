<template>
  <div>
    <AFrontHeader
      :isLoggedIn="isLoggedIn"
      :firstName="firstName"
      :profilePhoto="profilePhoto"
      :role="role"
    />
    <div class="classM">
      <div class="formClass">
        <form @submit.prevent="login">
          <h2>Login Here</h2>
          <input
            type="email"
            v-model="email"
            class="form-control fieldClass"
            id="inputEmail4"
            placeholder="E-mail or Username"
            aria-label="Email"
          />
          <input
            type="password"
            v-model="password"
            class="form-control fieldClass"
            id="inputPassword4"
            placeholder="Password"
            aria-label="Password"
          />
          <button type="submit" class="btn btn-outline-light">Login</button>

          <!-- Display error message if login fails -->
          <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

          <div class="body">
            <hr />
            <div class="login-text">Login With</div>
            <hr />
          </div>

          <div class="login-web">
            <div class="login-with">
              <img src="../assets/Form/googleIcon.png" alt="Google Icon" />
              <div>Continue with Google</div>
            </div>
            <div class="login-with">
              <img src="../assets/Form/emailWhiteIco.png" alt="Email Icon" />
              <div>Continue with Email</div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <FooterPart />
  </div>
</template>

<script>
import AFrontHeader from "@/components/AFrontHeader.vue";
import FooterPart from "@/components/FooterPart.vue";
import axios from "axios";


export default {
  components: {
    AFrontHeader,
    FooterPart,
  },
  data() {
    return {
      email: "",
      password: "",
      firstName: "",
      profilePhoto: "",
      role: "",
      isLoggedIn: false,
      errorMessage: "",
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(`/login`, {
          email: this.email,
          password: this.password,
        });

        const { token, firstName, profilePhoto, role, id } = response.data;

        localStorage.setItem("token", token);
        this.id = id;
        this.firstName = firstName;
        this.profilePhoto = profilePhoto;
        this.role = role;
        this.isLoggedIn = true;
        localStorage.setItem("id", id);
        localStorage.setItem("firstName", firstName);
        localStorage.setItem("profilePhoto", profilePhoto);
        localStorage.setItem("role", role);

        this.$emit('login-success', { firstName, profilePhoto, role, id });

        this.$router.push({
          name: "home",
          query: {
            token,
          },
        });
      } catch (error) {
        this.errorMessage = "Login failed: " + (error.response?.data?.message || error.message);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}

.login-with {
  display: flex;
  padding: 5px 0;
  gap: 10px;
  font-size: 16px;
  color: rgba(209, 209, 209, 0.747);
}

img {
  width: 30px;
  height: 30px;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
}

hr {
  width: 30%;
  height: 5px;
  background-color: rgb(255, 255, 255);
}

.login-text {
  color: white;
  padding: 5%;
  font-size: 16px;
}

.classM {
  background-color: black;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
}

h2 {
  color: white;
  text-align: center;
}

.formClass {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 500px;
  height: 80%;
  background-color: rgba(17, 0, 255, 0.39);
  background-image: linear-gradient(
    rgba(0, 0, 255, 0.466),
    rgba(255, 0, 191, 0.712)
  );
  border: 2px solid white;
  border-radius: 20px;
}

.form-control {
  width: 450px;
  margin-bottom: 5%;
}

@media only screen and (max-width: 500px) {
  .login-text {
    color: white;
    padding: 5%;
    font-size: 12px;
  }

  .classM {
    width: 100%;
    height: 500px;
  }

  .formClass {
    width: 80%;
    height: 100vh !important;
    border: 2px solid white;
    border-radius: 20px;
  }

  .form-control {
    width: 100%;
    margin-bottom: 5%;
  }
}
</style>
