<template>
    <div class="container">
      <div class="main"></div>
      <div class="circle">
        <img src="" alt="Profile Image" />
      </div>
      <div class="card cardArea">
        <div class="card-body">
          <h5 class="card-title d-flex justify-content-between align-items-center">
            Profile Information
            <button class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#editProfileModal">
              <i class="bi bi-pencil-fill"></i>
            </button>
          </h5>
          <p class="card-text">Name: John Doe</p>
          <p class="card-text">Email: johndoe@example.com</p>
          <!-- Add more profile information here -->
        </div>
      </div>
      
      <div class="modal fade" tabindex="-1" id="editProfileModal" aria-labelledby="editProfileModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit Profile</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>Modal body text goes here.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // Add profile data here
      };
    },
    methods: {
      editProfile() {
        // Add your edit profile logic here
        alert("Edit profile clicked!");
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .main {
    width: 100%;
    height: 200px;
    background-image: url('@/assets/Gallery/imgA.jpg');
    background-size: cover;
    background-position: center;
  }
  
  .circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-top: -100px;
    margin-left: 10%;
    background-image: url('@/assets/Gallery/imgA.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .circle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .cardArea {
    width: 100%;
    margin-top: 100px;
  }
  </style>
  