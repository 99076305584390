import { createApp } from 'vue'
import axios from 'axios';

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';

// axios.defaults.baseURL = 'http://127.0.0.1:8000/api';
axios.defaults.baseURL = 'https://graphicwebapi.mysoftcode.com/api';
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
// axios.defaults.headers.common['X-User-ID'] = localStorage.getItem('id');
// axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

console.log('Base URL:', axios.defaults.baseURL);




createApp(App).use(store).use(router).mount('#app')
