<template>
    <div>
      <!-- Include AFrontHeader to display user information -->
      <AFrontHeader
      :isLoggedIn="isLoggedIn"
      :firstName="firstName"
      :profilePhoto="profilePhoto"
      @update:isLoggedIn="handleLoginState"
      />

      <div>
        <BayerProfileA/>
      </div>
      
      <!-- Include BayerHeader for additional header content -->
    </div>
  </template>
  
  <script>
  import AFrontHeader from '@/components/AFrontHeader.vue';
  import BayerProfileA from '@/components/bayer/BayerProfileA.vue';
  
  export default {
  name: 'HomeView',
  components: {
    AFrontHeader,
    BayerProfileA,

    
},
data() {
    return {
      isLoggedIn: true, // or false based on initial state
      firstName: '', // default or fetched data
      profilePhoto: '' // default or fetched data
    }
  },
  methods: {
    handleLoginState(value) {
      this.isLoggedIn = value;
      if (!value) {
        this.firstName = '';
        this.profilePhoto = '';
      }
    }
  },
  mounted() {
    // Retrieve data from localStorage and set it in component data
    this.firstName = localStorage.getItem('firstName') || '';
    this.profilePhoto = localStorage.getItem('profilePhoto') || '';
  }
}
</script>
  
  <style lang="scss" scoped>
  /* Add your scoped styles here if needed */
  </style>
  