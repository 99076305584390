<template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Create New Item</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <form @submit.prevent="createItem" class="needs-validation" novalidate>

        <!-- Category ID -->
        <!-- <div>Category ID: {{ category_id }}</div> -->

        <!-- Response Message -->
        <div v-if="responseMessage" class="alert alert-success" role="alert">
          {{ responseMessage }}
        </div>
        
        <!-- Title -->
        <div class="mb-3">
          <label for="title" class="form-label">Title:</label>
          <input type="text" id="title" v-model="itemData.title" class="form-control" required>
          <div class="invalid-feedback" v-if="!itemData.title">Please provide a title.</div>
        </div>

        <!-- Description -->
        <div class="mb-3">
          <label for="description" class="form-label">Description:</label>
          <textarea id="description" v-model="itemData.description" class="form-control" required></textarea>
          <div class="invalid-feedback" v-if="!itemData.description">Please provide a description.</div>
        </div>

        <!-- Price -->
        <div class="mb-3">
          <label for="price" class="form-label">Price:</label>
          <input type="number" id="price" v-model="itemData.price" class="form-control" required>
          <div class="invalid-feedback" v-if="!itemData.price">Please provide a price.</div>
        </div>

        <!-- File -->
        <div class="mb-3">
          <label for="file" class="form-label">File:</label>
          <input type="file" id="item_file" @change="handleFileUpload" class="form-control" required>
          <div class="invalid-feedback" v-if="!itemData.file">Please choose a file.</div>
        </div>

        <!-- File Type Dropdown -->
        <div class="mb-3">
          <label for="fileType" class="form-label">File Type:</label>
          <select id="fileType" v-model="itemData.file_type" class="form-select" required>
            <option value="" disabled>Select File Type</option>
            <template v-if="category_id==1">
              <option value="EXR">EXR</option>
              <option value="HDR">HDR</option>
            </template>
            <template v-else-if="category_id==2">
              <option value="JPG">JPG</option>
              <option value="TIF">TIF</option>
              <option value="PNG">PNG</option>
            </template>
            <template v-else-if="category_id==3">
              <option value="OBJ">OBJ</option>
              <option value="FBX">FBX</option>
              <option value="C4D">C4D</option>
            </template>
            <template v-else-if="category_id==4">
              <option value="PNG">PNG</option>
              <option value="SVG">SVG</option>
            </template>
            <template v-else-if="category_id==5">
              <option value="PNG">PNG</option>
              <option value="ICO">ICO</option>
            </template>
            <template v-else-if="category_id==6">
              <option value="JPEG">JPEG</option>
              <option value="JPG">JPG</option>
            </template>
          </select>
          <div class="invalid-feedback" v-if="!itemData.file_type">Please select a File Type.</div>
        </div>



        <!-- Submit Button -->
        <button type="submit" class="btn btn-primary">Create Item</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    user_id: {
      type: Number,
      required: true
    },
    seller_id: {
      type: Number,
      required: true
    },
    category_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      itemData: {
        user_id: this.user_id,
        category_id: this.category_id,
        title: '',
        description: '',
        price: null,
        file: null,
        file_type: ''
      },
      responseMessage: '' // Initialize an empty response message
    };
  },
  methods: {
    handleFileUpload(event) {
      this.itemData.file = event.target.files[0];
    },
    
    createItem() {
      let formData = new FormData();
      formData.append('user_id', this.user_id);
      formData.append('seller_id', this.seller_id);
      formData.append('category_id', this.category_id);
      formData.append('title', this.itemData.title);
      formData.append('description', this.itemData.description);
      formData.append('price', this.itemData.price);
      formData.append('item_file', this.itemData.file);
      formData.append('file_type', this.itemData.file_type);

      axios.post('/items', formData)
        .then(response => {
          this.responseMessage = "Item created successfully!";
          console.log(response.data);
          // Optionally, reset the form fields after successful submission
          this.itemData.title = '';
          this.itemData.description = '';
          this.itemData.price = null;
          this.itemData.file = null;
          this.itemData.file_type = '';
          // Reload the page after 5 seconds
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        })
        .catch(error => {
          console.error(error);
          this.responseMessage = "Failed to create item.";
        });
    }
  }
};
</script>
