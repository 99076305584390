<template>
    <div>
      <section class="about-us-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h2 class="section-title">About Us</h2>
              <p class="about-us-description">
                Welcome to [Your Company Name], your go-to destination for high-quality graphic assets! Our mission is to provide creative professionals, businesses, and individuals with stunning visual resources that elevate their projects to new heights.
              </p>
              <p class="about-us-description">
                At [Your Company Name], we understand the importance of visual appeal in today's digital age. Whether you're a designer, marketer, or enthusiast, our platform offers a diverse collection of graphics, illustrations, icons, and more to meet your creative needs.
              </p>
              <p class="about-us-description">
                What sets us apart is our commitment to excellence. We collaborate with talented artists and designers to curate a selection of premium graphic assets. Our user-friendly platform powered by Vue.js 3 ensures a seamless browsing and purchasing experience.
              </p>
            </div>
            <div class="col-md-6">
              <!-- You can add an image or visual elements here to enhance the page -->
              <img src="../../assets/BackgroundSlide/backgroundC.jpg" alt="About Us Image" class="img-fluid">
            </div>
          </div>
        </div>
      </section>
  
      <section class="team-section">
        <div class="container">
          <h2 class="section-title">Meet Our Team</h2>
          <div class="row">
            <!-- Add team members with their details -->
            <div v-for="member in teamMembers" :key="member.id" class="col-md-4">
              <div class="team-member">
                <img src="../../assets/profile.png">
                <h4>{{ member.name }}</h4>
                <p class="team-role">{{ member.role }}</p>
                <p class="team-description">{{ member.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        teamMembers: [
          {
            id: 1,
            name: 'John Doe',
            role: 'Founder & CEO',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            avatar: '../../assets/BackgroundSlide/profile.png',
          },
          // Add more team members as needed
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  /* Add your custom CSS styles here */
  .about-us-section {
    padding: 80px 0;
  }
  
  .team-section {
    padding: 60px 0;
  }
  
  .section-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .about-us-description {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .team-member {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .team-member img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  .team-role {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .team-description {
    font-size: 16px;
    line-height: 1.6;
  }

  p{
    color: black;
  }
  </style>
  