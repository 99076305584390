<template>
  <div>
    <h1 style="color:white !important; text-align: center;">Images</h1>

    <div class="gallery">
      <div v-for="item in filteredItems" :key="item.id" class="card">
        <img :src="item.item_file" class="card-img full-img" alt="Item Image">
        <div class="card-content">
          <h5 class="card-title">{{ item.title }}</h5>
          <p class="card-description">{{ item.description }}</p>
          <p class="card-description">Price: ${{ item.price }}</p>
          <p class="card-description">Category: {{ item.category }}</p>

          <!-- Add to Cart Button -->
          <!-- <button @click="addToCart(item)" class="btn btn-primary">
            <i class="bi bi-cart-plus"></i> Add to Cart
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { storage } from '@/firebase/index';

export default {
  name: 'HomeView',
  props: ['user_id', 'seller_id', 'category_id'],
  data() {
    return {
      items: [],
    }
  },
  created() {
    console.log('Received User ID:', this.user_id);
    console.log('Received Category ID:', this.category_id);
    console.log('Received seller ID:', this.seller_id);
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => item.category_id === parseInt(this.category_id));
    }
  },
  methods: {
    fetchItems() {
      const token = localStorage.getItem('token');
      const id = localStorage.getItem('id');

      axios.get('/getitems', {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-User-ID': id,
        },
      })
      .then(response => {
        this.items = response.data.items;
        console.log('Fetched Items:', this.items);
      })
      .catch(error => {
        console.error('Error fetching items:', error);
      });
    },
    addToCart(item) {
      // Example function to handle adding items to the cart
      console.log('Adding to cart:', item);
      // You can implement your cart logic here (e.g., store items in local storage)
    },
    async uploadFile(file) {
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`items/${file.name}`);

      try {
        await fileRef.put(file);
        console.log('File uploaded successfully:', file.name);
        const downloadURL = await fileRef.getDownloadURL();
        console.log('Download URL:', downloadURL);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    },
  },
  mounted() {
    this.fetchItems();
  },
}
</script>

<style lang="scss" scoped>
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.card-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-content {
  padding: 15px;
}

.card-title {
  font-size: 1.5em;
  margin: 0 0 10px;
}

.card-description {
  font-size: 1em;
  color: #666;
}
</style>
