<template>
    <div>
        <h1>Hello</h1>
        <img :src="profilePhoto" alt="Profile Photo" />


    </div>
</template>

<script>
    export default {
        data() {
    return {
      token: "147|9TvAVMrgUt63GsVm2kfumlknSUEpaw0W2e0qyw8O573005a2",
      firstName: "Dinesh",
      profilePhoto: "MTcxODE4NTI0OF9wcm9maWxlLmpwZwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"
    };
  }

        
    }
</script>

<style lang="scss" scoped>
h1{
    color: black !important;
}

</style>