<template>
    <div>
      <div v-if="role == 1">
        <AFrontHeader
        :isLoggedIn="isLoggedIn"
        :firstName="firstName"
        :profilePhoto="profilePhoto"
        :role="role"
        @update:isLoggedIn="handleLoginState"
       />
      </div>

      <div  v-if="role == 2">
       <BFrontHeader 
       :isLoggedIn="isLoggedIn"
       :firstName="firstName"
       :profilePhoto="profilePhoto"
       :role="role"
       @update:isLoggedIn="handleLoginState"/>
      </div>

        <div>
        <AContact />
        </div>
        <div>
            <FooterPart />
        </div>

    </div>
</template>

<script>
import AFrontHeader from '@/components/AFrontHeader.vue'
import BFrontHeader from '@/components/BFrontHeader.vue'
import AContact from '@/components/contactUs/AContact.vue'
import FooterPart from '@/components/FooterPart.vue'

export default {
  name: 'HomeView',
  components: {
    AFrontHeader,
    BFrontHeader,
    AContact,
    FooterPart
    
},
data() {
    return {
      isLoggedIn: true, // or false based on initial state
      firstName: '', // default or fetched data
      profilePhoto: '', // default or fetched data
      role:''
    }
  },
  methods: {
    handleLoginState(value) {
      this.isLoggedIn = value;
      if (!value) {
        this.firstName = '';
        this.profilePhoto = '';
        this.role = '';
      }
    }
  },
  mounted() {
    // Retrieve data from localStorage and set it in component data
    this.firstName = localStorage.getItem('firstName') || '';
    this.profilePhoto = localStorage.getItem('profilePhoto') || '';
    this.role = localStorage.getItem('role') || '';
  }
}
</script>

<style lang="scss" scoped>

</style>