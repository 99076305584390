<template>
  <div class="container mt-5">
    <h2 class="mb-4">Cart Items</h2>
    <table class="table table-striped">
      <thead>
        <tr>
          <th></th>
          <th>Item ID</th>
          <th>Item Name</th>
          <th>Quantity</th>
          <th>Price</th>
          <th>Total</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in cartItems" :key="item.id">
          <td>
            <input type="checkbox" v-model="item.selected">
          </td>
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.quantity }}</td>
          <td>{{ item.price }}</td>
          <td>{{ item.quantity * item.price }}</td>
          <td>
            <button class="btn btn-danger btn-sm" @click="removeItem(item.id)">Remove</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="payment-summary mt-4">
      <h4>Payment Summary</h4>
      <p>Total Amount: {{ totalAmount }}</p>
      <!-- Conditionally open modal only if items are selected -->
      <button v-if="hasSelectedItems" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#bayerPaymentModal">Proceed to Payment</button>
      <button v-else class="btn btn-primary" disabled>Proceed to Payment</button>
    </div>

    <!-- Payment Modal -->
    <div class="modal fade" ref="paymentModal" id="bayerPaymentModal" tabindex="-1" aria-labelledby="bayerPaymentModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="bayerPaymentModalLabel">Payment Here</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Total Amount: {{ totalAmount }}</p>

            <!-- Payment method selection -->
            <div class="mb-3">
              <label for="paymentMethods" class="form-label">Select Payment Method:</label><br>
              <div id="paymentMethods">
                <div v-for="method in paymentMethods" :key="method.id">
                  <input type="radio" :id="'paymentMethod_' + method.id" v-model="selectedPaymentMethod" :value="method.name">
                  <label :for="'paymentMethod_' + method.id">{{ method.name }}</label><br>
                </div>
              </div>
            </div>

            <!-- Additional payment details -->
            <div v-if="selectedPaymentMethod === 'Credit Card'">
              <label for="creditCardNumber" class="form-label">Credit Card Number:</label>
              <input type="text" class="form-control" id="creditCardNumber" v-model="creditCardNumber">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="validateAndPay">Pay</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Error message -->
    <div v-if="showSelectItemsError" class="alert alert-danger mt-3" role="alert">
      You must select at least one item to proceed.
    </div>
  </div>
</template>

<script>
export default {
  name: 'CartItems',
  data() {
    return {
      cartItems: [
        { id: 1, name: 'Item 1', quantity: 2, price: 50, selected: false },
        { id: 2, name: 'Item 2', quantity: 1, price: 150, selected: false },
        { id: 3, name: 'Item 3', quantity: 3, price: 100, selected: false },
        // Add more cart items as needed
      ],
      paymentMethods: [
        { id: 1, name: 'Credit Card' },
        { id: 2, name: 'PayPal' },
        // Add more payment methods as needed
      ],
      selectedPaymentMethod: '',
      creditCardNumber: '',
      showSelectItemsError: false // Track if error message should be shown
    };
  },
  computed: {
    totalAmount() {
      return this.cartItems.reduce((total, item) => {
        return item.selected ? total + (item.quantity * item.price) : total;
      }, 0);
    },
    hasSelectedItems() {
      return this.cartItems.some(item => item.selected);
    }
  },
  methods: {
    removeItem(itemId) {
      this.cartItems = this.cartItems.filter(item => item.id !== itemId);
    },
    validateAndPay() {
      // Check if any item is selected
      const anyItemSelected = this.cartItems.some(item => item.selected);
      
      if (!anyItemSelected) {
        // Show error message
        this.showSelectItemsError = true;
      } else {
        // Reset error message
        this.showSelectItemsError = false;
        
        // Open the payment modal programmatically
        this.$refs.paymentModal.show();
      }
    },
    pay() {
      // Placeholder for payment logic
      console.log(`Payment of ${this.totalAmount} via ${this.selectedPaymentMethod} with credit card number ${this.creditCardNumber}`);
      
      // Implement your actual payment logic here
      
      // Reset modal data after payment
      this.selectedPaymentMethod = '';
      this.creditCardNumber = '';
      
      // Close modal
      this.$refs.paymentModal.hide();
    }
  }
};
</script>

<style scoped>
.table {
  width: 100%;
}
.payment-summary {
  border-top: 1px solid #dee2e6;
  padding-top: 15px;
}
</style>
