<template>
  <div style="background-color: blue;">
    <div>
      <BFrontHeader
        :isLoggedIn="isLoggedIn"
        :firstName="firstName"
        :profilePhoto="profilePhoto"
        @update:isLoggedIn="handleLoginState"
      />
    </div>


    <div style="display: flex;">
      <h5>Images Page</h5>
      <p>Category ID: {{ category_id }}</p>
      <p>User ID: {{ user_id }}</p>
      <p>Category table ID: {{ id }}</p>
    </div>


    <div class="CreateItem">

      <div class="cardItem">
        <button
          type="button"
          class="btn btn-outline-light cardBtn"
          data-bs-toggle="modal"
          data-bs-target="#ItemFormModal"
          style="align-items: center; justify-content: center; display: flex;"
        >
          <i class="bi bi-plus-lg" style="font-size: 60px; text-align: center;"></i>
        </button>
      </div>

      <div class="modal fade" id="ItemFormModal" tabindex="-1" aria-labelledby="ItemFormModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <ItemForm :user_id="user_id" :seller_id="id" :category_id="category_id"/> <!-- Pass user_id and id as props -->
        </div>
      </div>

    </div>
    
    <div>
      <ImagesItem :user_id="user_id" :category_id="category_id" :seller_id="id"/>
    </div>


    <FooterPart />
  </div>
</template>

<script>

import BFrontHeader from '@/components/BFrontHeader.vue'
import FooterPart from '@/components/FooterPart.vue'
import ItemForm from '@/components/seller/GIGItem/ItemForm.vue'
import ImagesItem from '@/components/seller/GIGItem/CategoryItem/ImagesItem.vue'

export default {
  name: 'HomeView',
  components: {
    BFrontHeader,
    FooterPart,
    ItemForm,
    ImagesItem,
  },
  props: ['user_id', 'id' , 'category_id'],
  data() {
    return {
      items: [],
      isLoggedIn: true,
      firstName: '',
      profilePhoto: '',
    }
  },
  created() {
    console.log('Received User ID:', this.user_id);
    console.log('Received ID:', this.id);
  },
  methods: {
    handleLoginState(value) {
      this.isLoggedIn = value;
      if (!value) {
        this.firstName = '';
        this.profilePhoto = '';
      }
    },
  },
  mounted() {
    this.firstName = localStorage.getItem('firstName') || '';
    this.profilePhoto = localStorage.getItem('profilePhoto') || '';
  },
}
</script>

<style lang="scss" scoped>

.item-image {
  max-width: 100%;
  height: auto;
}


.cardItem {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  width: 300px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 10px;
}

.card-content {
  padding: 10px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.card-text {
  margin-bottom: 5px;
}

.card-img {
  max-width: 100%;
  height: auto;
}

</style>