<template>
  <div>
    <h1 style="color:white !important; text-align: center;">3D Model</h1>
    <div class="gallery">
      <div v-for="item in items" :key="item.id" class="card">
        <div class="card-content">
          <h2 class="card-title">{{ item.title }}</h2>
          <p class="card-description">{{ item.description }}</p>
        </div>
        <div ref="threeContainer" class="three-container"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export default {
  data() {
    return {
      items: [
        {
          id: 1,
          title: 'Earth',
          description: 'This is a 3D model of Earth.',
          obj: '/earth.obj',
          texture: '/earthTextue.png',
        },
        {
          id: 2,
          title: 'Moon',
          description: 'This is a 3D model of the Moon.',
          obj: '/earth.obj',
          texture: '/moonTexture.png',
        },
        {
          id: 3,
          title: 'Mars',
          description: 'This is a 3D model of Mars.',
          obj: '/earth.obj',
          texture: '/marsTexture.png',
        },
        // Add more items as needed
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.items.forEach((item, index) => {
        this.initThree(this.$refs.threeContainer[index], item.obj, item.texture);
      });
    });
  },
  methods: {
    initThree(container, objPath, texturePath) {
      // Get the container dimensions and set the scene size
      const width = container.clientWidth;
      const height = container.clientHeight;
      
      // Set up scene, camera, and renderer
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(width, height);
      renderer.setClearColor(0x009999FF); // Set the clear color
      container.appendChild(renderer.domElement);

      // Add directional light
      const directionalLight = new THREE.DirectionalLight(0xFFFFFF, 1);
      directionalLight.position.set(5, 5, 5); // Adjust position as needed
      scene.add(directionalLight);

      // Add ambient light
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5); // Color, Intensity
      scene.add(ambientLight);

      // Load 3D object using OBJLoader
      const loader = new OBJLoader();
      loader.load(objPath, (object) => {
        object.position.set(0, 0, 0); // Adjust the position as needed
        object.scale.set(1, 1, 1); // Adjust the scale as needed

        // Load texture
        const textureLoader = new TextureLoader();
        const texture = textureLoader.load(texturePath, () => {
          object.traverse((child) => {
            if (child instanceof THREE.Mesh) {
              child.material.map = texture;
              child.material.needsUpdate = true;
            }
          });
        });

        scene.add(object);
      });

      // Set up camera position
      camera.position.set(0, 0, 10); // Adjust the camera position as needed
      camera.lookAt(0, 0, 0);

      // Create OrbitControls
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
      controls.dampingFactor = 0.25; // damping factor (0 = no damping, 1 = full damping)

      // Handle window resize
      window.addEventListener('resize', () => {
        const newWidth = container.clientWidth;
        const newHeight = container.clientHeight;
        renderer.setSize(newWidth, newHeight);
        camera.aspect = newWidth / newHeight;
        camera.updateProjectionMatrix();
      });

      // Animation loop
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true
        renderer.render(scene, camera);
      };

      animate();
    },
  },
};
</script>

<style scoped>
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.card-content {
  padding: 15px;
}

.card-title {
  font-size: 1.5em;
  margin: 0 0 10px;
}

.card-description {
  font-size: 1em;
  color: #666;
}

.three-container {
  width: 100%;
  height: 200px;
  background: #f0f0f0;
}
</style>
