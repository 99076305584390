<template>
    <div>

              <!-- Button trigger modal -->
      <div class="cardItem">
        <button type="button" class="btn btn-outline-light cardBtn" data-bs-toggle="modal" data-bs-target="#exampleModal" style="align-items: center; justify-content: center; display: flex;">
          <!-- <i class="bi bi-plus-square" ></i> -->
          <i class="bi bi-plus-lg" style="font-size: 60px; text-align: center;"></i>
        </button>
      </div>
      
      <div class="cardItem">
        <div v-for="item in items" :key="item.id" class="card" @click="goToCategoryPage(item)">
          <!-- <img :src="item.image" alt="Gallery Image" class="card-img"> -->
          <div class="card-content">
            <!-- <h2 class="card-title">{{ item.user_id }}</h2>
            <h2 class="card-title">{{ item.category_id }}</h2> -->
            <h2 class="card-title">{{ item.gig_title }}</h2>
            <p class="card-description">{{ item.category_name }}</p>
            <i class="bi bi-card-image"></i>
          </div>
         </div>
      </div>
   
  

  
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <PopupForm :id="id" :token="token"/>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import PopupForm from '@/components/seller/PopupForm.vue';
  
  export default {
    components: {
      PopupForm
    },
    data() {
      return {
        id: null,
        token: null,
        items: [] // Initially set to an empty array
      }
    },
    mounted() {
      this.getIdAndToken();
      this.fetchItems();
    },
    methods: {
      getIdAndToken() {
        this.id = localStorage.getItem('id');
        this.token = localStorage.getItem('token');
      },
      // fetchItems() {
      //   axios.get('http://127.0.0.1:8000/api/GetSeller') // Adjust this URL if needed
      //     .then(response => {
      //       this.items = response.data;
      //     })
      //     .catch(error => {
      //       console.error("There was an error fetching the items:", error);
      //     });
      // },
  
      fetchItems() {
    axios.get('/GetSeller', {
      headers: {
        Authorization: `Bearer ${this.token}`, // Include the token in the Authorization header
        'X-User-Id': this.id, // Include the id in a custom header (you can change the header name if needed)
      },
    })
    .then(response => {
      this.items = response.data;
    })
    .catch(error => {
      console.error("There was an error fetching the items:", error);
    });
  },
  
      goToCategoryPage(item) {
    let routeName;
    switch(item.category_name) {
      case 'HDRI':
        routeName = 'hdriView';
        break;
      case 'Images':
        routeName = 'imagesView';
        break;
      case '3D Model':
        routeName = '3dmodelView';
        break;
      case 'Photos':
        routeName = 'photosView';
        break;
      case 'Textures':
        routeName = 'texturesView';
        break;
      case 'PNGs':
        routeName = 'pngsView';
        break;
      default:
        console.error("Unknown category:", item.category);
        return;
    }
    // this.$router.push({ name: routeName, params: { user_id: item.user_id, id: item.id, token: this.token } });
    this.$router.push({ name: routeName, query: { user_id: item.user_id, id: item.id, category_id: item.category_id } });
  
  }
    }
  }
  </script>
  
  <style scoped>
  .cardItem {
    display: flex;
    flex-wrap: wrap;
  }
  .card {
    border: 1px solid #ddd;
    padding: 16px;
    border-radius: 8px;
    margin: 16px;
    width: calc(33.333% - 32px); /* Adjust the width as needed */
    box-sizing: border-box;
    cursor: pointer;
  }
  
  .card-content {
    text-align: center;
  }
  .card-title {
    font-size: 1.25em;
    margin: 0 0 8px;
  }
  .card-description {
    font-size: 1em;
    color: #666;
  }
  
  .cardBtn {
    /* border: 1px solid #ddd; */
    padding: 16px;
    border-radius: 8px;
    margin: 16px;
    width: calc(33.333% - 32px); /* Adjust the width as needed */
    box-sizing: border-box;
  }
  </style>
  