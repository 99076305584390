<template>
  <div class="home">

    <div>
        <AFrontHeader
        :isLoggedIn="isLoggedIn"
        :firstName="firstName"
        :profilePhoto="profilePhoto"
        :role="role"
        @update:isLoggedIn="handleLoginState"
       />
      </div>


    <div>
      <CSliderPage />
    </div>
    <div>
      <ThreeD />
    </div>
    <div>
      <DSecondPart />
    </div>
    <div>
      <BWelcomePage />
    </div>
    <div>
      <FooterPart />
    </div>
  </div>
</template>

<script>
import AFrontHeader from '@/components/AFrontHeader.vue';
import BWelcomePage from '@/components/BWelcomePage.vue';
import CSliderPage from '@/components/CSliderPage.vue';
import DSecondPart from '@/components/DSecondPart.vue';
import FooterPart from '@/components/FooterPart.vue';
import ThreeD from '@/components/ThreeD.vue';

export default {
  name: 'HomeView',
  components: {
    AFrontHeader,
    CSliderPage,
    ThreeD,
    BWelcomePage,
    DSecondPart,
    FooterPart
  },
  data() {
    return {
      isLoggedIn: false,
      firstName: '',
      profilePhoto: '',
      role: '',
    };
  },
  methods: {
    handleLoginState(value) {
      this.isLoggedIn = value;
      if (!value) {
        this.firstName = '';
        this.profilePhoto = '';
        this.role = '';
      } else {
        this.firstName = localStorage.getItem('firstName') || '';
        this.profilePhoto = localStorage.getItem('profilePhoto') || '';
        this.role = localStorage.getItem('role') || '';
      }
    },
    
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token) {
      this.isLoggedIn = true;
      this.firstName = localStorage.getItem('firstName') || '';
      this.profilePhoto = localStorage.getItem('profilePhoto') || '';
      this.role = localStorage.getItem('role') || '';
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
}
</style>
