<template>
    <div>
        <BFrontHeader
      :isLoggedIn="isLoggedIn"
      :firstName="firstName"
      :profilePhoto="profilePhoto"
      @update:isLoggedIn="handleLoginState"
    />
        <div>
        <AGallery />
        </div>
        <div>
            <FooterPart />
        </div>

    </div>
</template>

<script>
import BFrontHeader from '@/components/BFrontHeader.vue'
import AGallery from '@/components/gallery/AGallery.vue'
import FooterPart from '@/components/FooterPart.vue'

export default {
  name: 'HomeView',
  components: {
    BFrontHeader,
    AGallery,
    FooterPart
    
},
data() {
    return {
      isLoggedIn: true, // or false based on initial state
      firstName: '', // default or fetched data
      profilePhoto: '' // default or fetched data
    }
  },
  methods: {
    handleLoginState(value) {
      this.isLoggedIn = value;
      if (!value) {
        this.firstName = '';
        this.profilePhoto = '';
      }
    }
  },
  mounted() {
    // Retrieve data from localStorage and set it in component data
    this.firstName = localStorage.getItem('firstName') || '';
    this.profilePhoto = localStorage.getItem('profilePhoto') || '';
  }
}
</script>

<style lang="scss" scoped>

</style>