<template>
    <div style="height: 20dvh; background-image: linear-gradient(black,blue); width: 100%;">

        <div style="display: flex; justify-content: center; align-items: center; gap: 50px; width: 100%; height: 100%;">
            <div class="circle">HDRI</div>
            <div class="circle">Texture</div>
            <div class="circle">PNG</div>
            <div class="circle">3D Model</div>
            <div class="circle">Images</div>
            <div class="circle">Photos</div>
        </div>


    </div>
    <div style="width: 100%; height: 2dvh; background-color: rgb(76, 0, 255); opacity: 1;">

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

.circle{
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    font-size: 14px;
    text-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
}


</style>