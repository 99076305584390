<template>
  <div style="width: 100%; height: 90vh; margin: 0;">
    <div class="background">
      <!-- Your content goes here -->

      <div class="whiteShape" style="color: rgb(211, 211, 211);">

        <div>
        <div>
          <h1>Welcom To Grapphic Univers</h1>
          <h3>Get Your Grapical Assets</h3>

        </div>
        

        <div class="searchBar">
          <form class="d-flex" role="search">
            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
            <button class="btn btn-primary" type="submit">Search</button>
          </form>
        </div>
      </div>

     <!-- <EarthHD /> -->


      </div>








    </div>
  </div>
</template>

<script>
// import EarthHD from '@/components/EarthHD.vue'
export default {

  components: {
    // EarthHD,
  }
  // Your component logic goes here
}
</script>

<style lang="scss" scoped>

.whiteShape{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-items: center;
  padding: 5%;

  background-image: radial-gradient(rgba(0, 0, 255, 0.438),rgba(0, 0, 0, 0.384));
  // background-image: url(../assets/BackgroundSlide/imgABC.png);
  // background-size: cover;
  // opacity: 0.6;
}


.searchBar{
  width: 25dvw;
}
.background {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-size: cover;
  animation: backgroundAnimation 20s infinite alternate; /* Adjust the duration as needed */
}

@keyframes backgroundAnimation {
  0% {
    background-image: url(../assets/BackgroundSlide/backgroundA.jpg);
  }

  50% {
    background-image: url(../assets/BackgroundSlide/backgroundB.jpg);
  }

  100% {
    background-image: url(../assets/BackgroundSlide/backgroundC.jpg);
  }
}







</style>
