<template>
    <div style="height: 100%; width: 100%; background-color: red;">
      <div style="display: flex;">
        <div style="width: 20%; text-align: center; color: white; display: flex; justify-content: center; align-items: center; background-color: black;">
          <router-link to="/" class="navbar-brand">
            <img src="@/assets/graphic.png" alt="Logo" width="30" height="24" class="d-inline-block align-text-top">
            Graphic Univers
          </router-link>
        </div>
  
        <ul class="nav justify-content-center" style="display: flex; width: 60%;">
          <li class="nav-item">
            <NavItem to="/dashboard" class="nav-link" @click="viewSellerProfile" style="cursor: pointer;" >Dashboard</NavItem>
          </li>
          
          <li class="nav-item">
            <router-link to="/sellerHome/about" class="nav-link">About</router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link to="/gallery" class="nav-link">Gallery</router-link>
          </li> -->
          <li class="nav-item">
            <router-link to="/mygallery" class="nav-link">My Gallery</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/sellerHome/contact" class="nav-link">Contact</router-link>
          </li>
        </ul>
  
        <div style="width: 20%; text-align: center; color: white; background-color: black; justify-content: center; align-items: center; display: flex;">
          <template v-if="!isLoggedIn">
            <router-link to="/register"><button type="button" class="btn btn-outline-light">Sign In</button></router-link>
            <router-link to="/login"><button type="button" class="btn btn-outline-light">Login</button></router-link>
          </template>
          <template v-else>
            <div style="display: flex; align-items: center;">
              <div class="dropdown">
                <button class="btn btn-outline-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                  <span style="margin-right: 10px;">{{ firstName }}</span>
                  <img :src="profilePhoto" alt="IMG" width="30" height="30" style="border-radius: 50%;">
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <template v-if="role === 2">
                    <button class="dropdown-item" @click="viewSellerProfile">Seller Profile</button>
                    <button class="dropdown-item" @click="viewBuyerProfile">Buyer Profile</button>
                  </template>
                  <template v-else>
                    <button class="dropdown-item" @click="viewProfile">Profile</button>
                  </template>
                  <button class="dropdown-item" @click="logout">Logout</button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    props: {
      isLoggedIn: Boolean,
      firstName: String,
      profilePhoto: String
    },
    data() {
      return {
        role: null
      };
    },
    mounted() {
      this.getUserRole();
    },
    methods: {
      getUserRole() {
        const token = localStorage.getItem('token'); // Assuming your token key is 'token'
  
        if (!token) {
          console.error('Token is missing.');
          return;
        }
  
        axios.get('/user/role', {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then(response => {
          this.role = response.data.role;
        })
        .catch(error => {
          console.error('Error fetching user role:', error);
        });
      },
      viewSellerProfile() {
        this.$router.push({ path: '/sellerhome', props: { isLoggedIn: true, userId: this.userId, firstName: this.firstName, profilePhoto: this.profilePhoto } });
      },
      viewBuyerProfile() {
        this.$router.push({ path: '/bayerhome', props: { isLoggedIn: true, userId: this.userId, firstName: this.firstName, profilePhoto: this.profilePhoto } });
      },
      viewProfile() {
        this.$router.push({ path: '/profileview', props: { isLoggedIn: true, userId: this.userId, firstName: this.firstName, profilePhoto: this.profilePhoto } });
      },
      logout() {
        localStorage.clear();
        this.$emit('update:isLoggedIn', false);
        this.$router.push('/');
      }
    }
  };
  </script>
  
  <style scoped>
  .dropdown-menu {
    background-color: black;
    color: white;
  }
  
  .dropdown-item {
    color: white !important;
  }
  
  .dropdown-item:hover {
    color: rgb(0, 0, 0) !important;
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: black;
    display: flex;
    align-items: center;
    justify-items: center;
  }
  
  .btn-outline-light {
    margin: 10px;
  }
  </style>
  