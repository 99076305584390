// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA5AqnOBMPmRgGGLcXeREBKJ7c9tu0Zt8s",
  authDomain: "graphic-web-app.firebaseapp.com",
  projectId: "graphic-web-app",
  storageBucket: "graphic-web-app.appspot.com",
  messagingSenderId: "637841875888",
  appId: "1:637841875888:web:a219e9965d990e7ace6c75",
  measurementId: "G-ZEFDYKK3G6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

import {getStorage} from "firebase/storage";
const storage = getStorage(app);

export {app, storage}