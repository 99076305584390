<template>
    <div>
      <h1 style="color:white !important; text-align: center;">HDRIs</h1>
      <div class="gallery">
        <div v-for="item in items" :key="item.id" class="card">
          <div class="card-content">
            <h2 class="card-title">{{ item.title }}</h2>
            <p class="card-description">{{ item.description }}</p>
          </div>
          <div :ref="'threeContainer' + item.id" class="three-container"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import * as THREE from 'three';
  import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
  
  export default {
    name: 'HDRIModelPage',
    data() {
  return {
    items: [
      { id: 1, title: 'Model 1', description: 'Description for model 1', hdrPath: '/qwantani_2k.hdr' },
      { id: 2, title: 'Model 2', description: 'Description for model 2', hdrPath: '/lythwood_room_4k.hdr' },
      { id: 3, title: 'Model 3', description: 'Description for model 3', hdrPath: '/HDR_029_Sky_Cloudy_Ref.hdr' }
    ]
  };
},

    mounted() {
      this.items.forEach(item => this.initThree(item.id));
    },
    methods: {
        initThree(id) {
  const container = this.$refs['threeContainer' + id][0];
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(100, container.clientWidth / container.clientHeight, 0.1, 1000);
  const renderer = new THREE.WebGLRenderer();

  renderer.setSize(container.clientWidth, container.clientHeight);
  container.appendChild(renderer.domElement);

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableZoom = false; // Disable zooming
  camera.position.set(0, 0, 1);
  controls.update();

  const rgbeLoader = new RGBELoader();
  const item = this.items.find(item => item.id === id);
  rgbeLoader.load(item.hdrPath, (texture) => {
    texture.mapping = THREE.EquirectangularReflectionMapping;
    scene.background = texture;

    const geometry = new THREE.SphereGeometry(0.5, 32, 32);
    const material = new THREE.MeshStandardMaterial({ envMap: texture });
    const sphere = new THREE.Mesh(geometry, material);
    scene.add(sphere);

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };

    animate();
  });
}

    }
  };
  </script>
  
  <style scoped>
  body, html, #app {
    margin: 0;
    height: 100%;
    overflow: hidden;
    background-color: #000; /* To contrast the white text */
  }
  
  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .card {
    background-color: #333;
    border-radius: 8px;
    overflow: hidden;
    margin: 10px;
    width: calc(33% - 20px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .card-content {
    padding: 16px;
    color: white;
  }
  
  .card-title {
    margin: 0;
    font-size: 1.5em;
  }
  
  .card-description {
    margin: 8px 0 0;
  }
  
  .three-container {
    width: 100%;
    height: 200px; /* Adjust height as needed */
    background-color: #000;
  }
  </style>
  